import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';

const translation = {
  'de-DE': 'ALKOProdDeutschland',
  'de-AT': 'ALKOProdDeutschland',
  'pl-PL': 'ALKOProdPolski',
  'sv-SE': 'ALKOProdSvenska',
  'nb-NO': 'ALKOProdNorsk',
  'da-DK': 'ALKOProdDansk',
  'it-IT': 'ALKOProdItaly',
  'hu-HU': 'ALKOProdHungary',
  'cs-CZ': 'ALKOProdCzech',
  'sk-SK': 'ALKOProdSlovakia',
  'fr-FR': 'ALKOProdFrance',
  'en-GB': 'ALKOProdUk',
  'sl-SL': 'ALKOProdSlovenia',
  'hr-HR': 'ALKOProdCroatia',
};

const pimLangauges = {
  DE: 'de-DE',
  AT: 'de-AT',
  PL: 'pl-PL',
  NB: 'nb-NO',
  NO: 'no-NO',
  SV: 'sv-SE',
  SE: 'sv-SE',
  HU: 'hu-HU',
  CS: 'cs-CZ',
  CZ: 'cs-CZ',
  HR: 'hr-HR',
  IT: 'it-IT',
  RU: 'ru-RU',
  UK: 'uk-UA',
  UA: 'uk-UA',
  SK: 'sk-SK',
  DA: 'da-DK',
  DK: 'da-DK',
  FR: 'fr-FR',
  SL: 'sl-SI',
  SI: 'sl-SI',
  EN: 'en_GB',
  GB: 'en_GB',
  CH: 'de_CH',
  FI: 'fi-FI',
};

@Injectable({
  providedIn: 'root',
})
export class ShopnannyService {
  private baseUrl = 'https://api.shop-nanny.com';
  private headers = {
    ['x-channel']: 'ALKOProd',
    ['x-api-key']: '9c5f4f2d-4ca3-47d2-a79d-5249d114101d',
    ['x-filter']: 'datatype;e;product',
    ['x-datatype']: 'product',
    ['Authorization']: 'Basic ' + btoa('alkoGeraete:Dt1xGwlJ3hBr2o='),
    ['x-fields']: 'artnumber,title',
  };

  constructor(
    private http: HttpClient,
    private userService: UserService,
  ) {
    this.setLanguage();
  }

  private setLanguage() {
    const alkoCulture = this.userService.getAlkoCulture();
    const language = this.getPimLanguage(alkoCulture);
    this.headers['x-languages'] = language;
  }

  private getPimLanguage(alkoCulture: string): string {
    if (pimLangauges[alkoCulture] !== undefined) {
      return pimLangauges[alkoCulture];
    }
    return 'de_DE';
  }

  private setChannel() {
    if (window.location.hostname.includes('localhost')) {
      this.headers['x-channel'] = 'ALKOProdDeutschland';
    } else {
      const languageCode = window.location.pathname.split('/')[1];
      if (translation[languageCode] !== undefined) {
        this.headers['x-channel'] = translation[languageCode];
      } else {
        this.headers['x-channel'] = 'ALKOProdDeutschland';
      }
    }
  }

  /**
   * The response schema of /datahub/search is as following:
   * 
   * {
    "elements": [
        {
            "id": "93756",
            "artnumber": "495541",
            "title": "Hauptplatine Robo 1000 W Kpl 119973 Et"
        },
        {
            "id": "161791",
            "artnumber": "457929",
            "title": "Karton Com 46 Br  Gardenfeelings 119920"
        },
        ...
      ]
    }

   * 
   * @param response
   * @returns
   */
  private getFullNameOfShopnannyResponse(item: {
    id: string;
    artnumber: string;
    title: string;
  }): ArticleMinInfo {
    return {
      articleNumber: item.artnumber,
      title: item.title,
    };
  }

  private _getSeachHeaders(searchOnlyArtnumber: boolean) {
    const headers = {
      ...this.headers,
      ...(searchOnlyArtnumber ? { 'x-excludesearchfields': 'title' } : {}),
      ...{ 'x-limit': '10' },
    };
    return headers;
  }

  private search(
    searchTerm: string,
    searchOnlyArtnumber: boolean,
  ): Observable<ArticleMinInfo[]> {
    const url = `${this.baseUrl}/datahub/search`;
    const headers = this._getSeachHeaders(searchOnlyArtnumber);
    const params = { q: searchTerm };

    const response = this.http
      .get<any>(url, { headers: headers, params: params })
      .pipe(
        map((response) => {
          return response.elements.map(this.getFullNameOfShopnannyResponse);
        }),
      );

    return response;
  }

  public searchProductByArticleNumber(
    articleNumber: string,
  ): Observable<ArticleMinInfo[]> {
    return this.search(articleNumber, true).pipe(
      map((articles) =>
        articles.sort(
          // sort articlenumbers descending
          (a, b) => {
            if (a.articleNumber < b.articleNumber) return -1;
            if (a.articleNumber > b.articleNumber) return 1;
            return 0;
          },
        ),
      ),
    );
  }

  public searchProductByArticleTitle(
    title: string,
  ): Observable<ArticleMinInfo[]> {
    return this.search(title, false);
  }

  getKnowledgeItemsTags(): Observable<Tag[]> {
    const url = `${this.baseUrl}/filter/all`;
    const headers = {
      ...this.headers,
      ...{
        'x-fields': 'title,name,id',
        'x-datatype': 'knowledgeitem',
      },
    };
    return this.http.get(url, { headers: headers }).pipe(
      map((response: any) => {
        console.log('response:', response);
        return response.filter?.tag?.elements as Tag[];
      }),
    );
  }
}

type Tag = {
  key: string;
  doc_count: number;
};

export type ArticleMinInfo = {
  articleNumber: string;
  title: string;
};
